import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import _ from 'lodash'
import FileSaver from 'file-saver'
import { connect } from 'react-redux'

import { Modal } from '@material-ui/core'

import { requestFile } from 'utils/request'
import DownloadWellDataReportModalForm from '../components/DownloadWellDataReportForm'
import styles from './DownloadWellDataReportModalContainer.module.scss'
import { getAuthStoreData } from 'utils/authStore'
import { showSnackbar } from 'common/actions/snackbar'

const DownloadWellDataReportModalContainer = props => {
  const [open] = useState(true)
  const { hideModal, systems, showSnackbar } = props

  let systemsByGroup = _.groupBy(systems, 'group')

  const handleSubmit = async data => {
    if (props.isSubmitting) return

    let startDate = new Date(data.startDate)
    startDate.setHours(0, 0, 0, 0)

    let endDate = new Date(data.endDate)
    endDate.setHours(23, 59, 59, 59)

    const authStoreData = getAuthStoreData()
    try {
      const response = await requestFile(
        `systems/` +
          data.deviceId +
          `/well/` +
          startDate.toISOString() +
          `/` +
          endDate.toISOString(),
        {
          method: 'POST',
          headers: {
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'content-type': 'application/json',
            Authorization: authStoreData
          }
        }
      )

      if (!response) {
        showSnackbar('snackbar.whoops')
        return
      }

      const blob = await response.blob()
      const fileName = response.headers.get('filename')
      FileSaver.saveAs(blob, fileName)
    } catch (error) {
      showSnackbar('snackbar.whoops') // Puedes personalizar el mensaje si lo deseas
    }

    hideModal()
  }

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <DownloadWellDataReportModalForm
          {...props}
          onSubmit={handleSubmit}
          systems={systems}
          systemsByGroup={systemsByGroup}
        />
      </div>
    </Modal>
  )
}

DownloadWellDataReportModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapActionsToProps = {
  showSnackbar
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(DownloadWellDataReportModalContainer)
)
